
import { PackageViewMode } from '@/common/enums/PackageViewMode.enum';
import QuantityTicker from '@/common/components/quantity-ticker.component.vue';
import MonthAvailability from '@/common/components/month-availability.component.vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, ProvideReactive } from 'vue-property-decorator';
import { Item } from '@/common/models/Item.model';
import { orderModule } from '@/modules/order/orderModule.vuex-module';
import { format, addDays, isSameDay, startOfMonth } from 'date-fns';
import isFuture from 'date-fns/isFuture';
import isToday from 'date-fns/isToday';
import OrderActivity from '@/modules/order/common/classes/OrderActivity.class';
import ActivitySelector from '../components/activity-selector.component.vue';
import { DayOfTheMonth } from '@/common/models/DayOfTheMonth.model';

@Options({
	name: 'DateView',
	components: { QuantityTicker, MonthAvailability, ActivitySelector },
})
export default class DateView extends Vue {
	@Prop({ default: '0' }) clientLocationKey!: string;
	@Prop({ default: PackageViewMode.INDIVIDUAL }) type!: PackageViewMode;
	@Prop({ default: '0' }) typekey!: string;
	// Create our number formatter.
	today = new Date();
	todaySOM = startOfMonth(this.today);
	tomorrow = addDays(new Date(), 1);
	tomorrowSOM = startOfMonth(this.tomorrow);

	mounted(): void {
		orderModule.setOpenActivity(this.item?.Activities[0].EntityHierarchyKey ?? 0);
		this.activity?.getMonthAvailability(this.todaySOM);
		if (!isSameDay(this.todaySOM, this.tomorrowSOM)) {
			this.activity?.getMonthAvailability(this.tomorrowSOM);
		}
	}
	get selectedDate(): Date | null | undefined {
		return this.activity?.selectedDate;
	}
	get todayString(): string {
		return format(this.today, 'eeee, MMM d');
	}
	get isTodaySelected(): boolean {
		if (!this.selectedDate) {
			return false;
		}
		return isSameDay(this.today, this.selectedDate!);
	}
	get isPackage(): boolean {
		return !!this.item?.PackageKey;
	}
	get item(): Item | null {
		return orderModule.openItem;
	}
	@ProvideReactive()
	get activity(): OrderActivity | null {
		return orderModule.openActivity;
	}
	get tomorrowString(): string {
		return format(this.tomorrow, 'eeee, MMM d');
	}
	get isTomorrowSelected(): boolean {
		if (!this.selectedDate) {
			return false;
		}
		return isSameDay(this.tomorrow, this.selectedDate!);
	}
	get isOtherSelected(): boolean {
		if (!this.selectedDate) {
			return false;
		}
		return !this.isTodaySelected && !this.isTomorrowSelected;
	}
	get otherString(): string {
		return format(this.selectedDate ?? this.today, 'eeee, MMM d');
	}
	get thirdDay(): Date {
		return addDays(new Date(), 2);
	}
	get canProceed(): boolean {
		return !!this.activity?.selectedDate;
	}
	next(): void {
		if (orderModule.allDatesSelected) {
			this.$router.push(`/portal/${this.clientLocationKey}/${this.type}/${this.typekey}/book/time`);
		} else if (this.activity?.selectedDate) {
			let nextUnfinished = orderModule.itemActivities.find((activity) => !activity.selectedDate);
			orderModule.setOpenActivity(nextUnfinished?.entityHierarchyKey ?? 0);
			this.activity?.getMonthAvailability(this.todaySOM);
		}
	}
	getAvailabilityForDate(date: Date): DayOfTheMonth[] | null {
		return this.activity?.monthAvailability.get(format(date, 'MMMM-dd-yyyy')) ?? null;
	}
	warningFor(date: Date): string | null {
		let som = startOfMonth(date);
		let items = this.getAvailabilityForDate(som);
		if (!items) {
			return null;
		}
		let item = items.find((item) => isSameDay(addDays(som, item.Day - 1), date));
		if (!item) {
			return null;
		}
		if (item.Closed) {
			return 'Closed';
		} else if (item.SoldOut) {
			return 'Sold Out';
		}
		// else if (!item.Available) {
		// 	return 'No available times';
		// }
		return null;
	}
	setDate(event: Date): void {
		if (isFuture(event) || isToday(event)) {
			this.activity?.selectDate(event);
		} else {
			console.log('NOT SELECTING DATE');
		}
	}
}
